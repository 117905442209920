<template>
    <div id="offers">
      <div class="container-fluid">
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-form-input
                  type="text"
                  :placeholder="$t('filter-title')"
                  v-model="filterData[`${$i18n.locale}_title`]"
                  required
                ></b-form-input>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('sidebar.offers') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button v-if="canAccess(['store-offer'])" variant="primary" @click="addInit" size="sm">{{ $t('add') }}</b-button>
            </template>
            <template v-slot:body>
              <b-row v-if="canAccess(['get-offer' , 'update-offer' , 'delete-offer'])">
                <b-col sm="12" md="6" lg="4" class="mb-3" v-for="(offer,index) in offers.data" :key="index">
                    <div class="offer-card p-2 border rounded">
                        <img :src="offer.image_path" style="max-height: 180px; width: 100%; object-fit: cover;" class="img-fluid rounded mb-3" alt="">
                        <div class="title d-flex align-items-center flex-wrap justify-content-between">
                            <h6 class="font-weight-bold">{{ offer[`${$i18n.locale}_title`] }}</h6>
                            <b-badge variant="primary">
                                {{offer.percentage}}
                                <i class="fas fa-percentage"></i>
                            </b-badge>
                        </div>
                        <ul class="list-unstyled text-primary">
                            <li>{{ offer.start_date }} - {{ offer.end_date }}</li>
                        </ul>
                        <p class="" style="font-size: 14px;">{{ offer[`${$i18n.locale}_description`] }}</p>
                        <div class="controller" v-if="canAccess(['update-offer' , 'delete-offer'])">
                            <b-row>
                                <b-col v-if="canAccess(['update-offer'])" >
                                    <b-button  class="w-100" variant=" iq-bg-success" @click="update(offer.id)"  size="md"><i class="ri-ball-pen-fill p-0">
                                      {{ $t('edit') }} </i></b-button>
                                </b-col>
                                <b-col v-if="canAccess(['delete-offer'])">
                                    <b-button   class="w-100" variant=" iq-bg-danger"  size="md" @click="remove(offer.id)"><i class="ri-delete-bin-line p-0">{{ $t('remove') }}</i></b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                  v-model="filterData.page"
                  :total-rows="offers.total"
                  :per-page="offers.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
<script>
import i18n from '../../i18n'

export default {
  name: 'offers',
  data () {
    return {
      filterData: {
        page: 1,
        ar_title: '',
        en_title: '',
        kr_title: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: 'Name',
          key: 'en_name'
        },
        {
          label: 'Created Date',
          key: 'created_at'
        },
        {
          label: 'Created By',
          key: 'created_by.name'
        },
        {
          label: 'Updated Date',
          key: 'updated_at'
        },
        {
          label: 'Updated By',
          key: 'updated_by.name'
        },
        {
          label: 'Actions',
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    i18n () {
      return i18n
    },
    filtering () {
      this.filterData.page = 1
      this.getOffers(this.filterData)
    },
    addInit () {
      this.$router.push({
        path: 'add-offers',
        params: {
          id: ''
        }
      })
    },
    update (id) {
      this.$router.push(`/add-offers/${id}`)
    },
    remove (id) {
      this.confermMsg(() => {
        this.removeOffers(id)
      })
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getOffers(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getOffers()
  }
}
</script>
